// Types
import { Cart } from '@white-label-types/parking-checkout';

// Helpers
import { httpCart } from '@white-label-configuration/http';
import { parseDiscountCodeData } from '@white-label-helper/discount-code-parser';

import { myApp } from '@white-label-plugin/global-app-context';
import { datadogRum } from '@datadog/browser-rum';

const isBookingPortal = process.env.NUXT_ENV_IS_BOOKING_PORTAL === 'true';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
httpCart.onRequest((config) => {
  const session_id = datadogRum?.getInternalContext()?.session_id || null;
  if (isBookingPortal) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    config.headers.Authorization = `Bearer ${myApp.$auth?.user['https://user_metadata/channel_token']}`;
  }
  config.headers['X-Datadog-Session-ID'] = session_id;
});

export interface RemoveDiscountResponse {
  items: Cart['items'];
  totals: Cart['totals'];
  orderTotals: Cart['totals'];
  payable: Cart['payable'];
}

export const validateDiscountCode = (
  cartToken: string,
  discountCode: string,
): Promise<ReturnType<typeof parseDiscountCodeData>> => httpCart.patch(`/public/cart/${cartToken}/discount/apply/${discountCode.trim()}`)
  .then(({ body }) => parseDiscountCodeData(body.data));

export const removeDiscountCode = (
  cartToken: string,
  discountCode: string,
): Promise<RemoveDiscountResponse> => httpCart.patch(`/public/cart/${cartToken}/discount/remove/${discountCode}`)
  .then(({ body }) => ({
    items: body.data.items,
    totals: body.data.items[0].totals,
    orderTotals: body.data.totals,
    payable: body.payable,
  }));
