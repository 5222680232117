import { render, staticRenderFns } from "./payment-form.vue?vue&type=template&id=f2232c26&scoped=true"
import script from "./payment-form.vue?vue&type=script&lang=ts"
export * from "./payment-form.vue?vue&type=script&lang=ts"
import style0 from "./payment-form.pcss?vue&type=style&index=0&id=f2232c26&prod&lang=postcss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2232c26",
  null
  
)

export default component.exports