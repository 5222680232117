// Packages
import { computed, type Ref, ref } from 'vue';
import { defineStore } from 'pinia';

// Helpers
import {
  removeDiscountCode as helperRemoveDiscountCode,
  validateDiscountCode as helperValidateDiscountCode,
} from '@white-label-helper/api-parkings-discount-code';
import { formatPrice as helperFormatPrice } from '@white-label-helper/helper-payment';

// Types
import type { ParsedDiscount } from '@white-label-types/parking-checkout';
import type { DiscountCodeState } from '@white-label-types/stores';

// Stores
import useDepositStore from '@white-label-store/deposits';

export const useDiscountCodeStore = defineStore('discountCode', () => {
  const state: Ref<DiscountCodeState> = ref({
    code: '',
    amount: 0,
    description: '',
    subtotal: 0,
    total: 0,
    deepLinkCode: '',
  });

  const currentDeepLinkCode =
    computed(() => state.value.code || state.value.deepLinkCode);

  const discountAmount =
    computed(() => state.value.amount);

  const discountCode =
    computed(() => state.value.code);

  const discountObject =
    computed(() => ({
      ...state.value,
      amountFormatted: helperFormatPrice(state.value.amount),
      subtotalFormatted: helperFormatPrice(state.value.total),
      totalFormatted: helperFormatPrice(state.value.total),
    }));

  const discountQuery =
    computed(() =>
      new URLSearchParams({
        discount: currentDeepLinkCode.value,
      }).toString()
    );

  const subtotal =
    computed(() => state.value.subtotal);

  const total =
    computed(() => state.value.total);

  const clearDiscountCode = () => {
    state.value.code = '';
    state.value.amount = 0;
    state.value.description = '';
    state.value.subtotal = 0;
    state.value.total = 0;
  };

  const storeDeepLinkCode = (payload: string) => {
    state.value.deepLinkCode = payload;
  };

  const storeDiscountCode = (payload: ParsedDiscount) => {
    state.value.code = payload.code;
    state.value.amount = payload.amount;
    state.value.description = payload.description;
    state.value.subtotal = payload.subtotal;
    state.value.total = payload.total;
  };

  const validateDiscountCode = async ({
    cartToken,
    code,
  }: {
    cartToken: string;
    code: string;
  }) => {
    if (!code) {
      clearDiscountCode();
      return new Promise<void>((resolve) => {
        resolve();
      });
    }
    return helperValidateDiscountCode(cartToken, code).then((response) => {
      if (response) {
        useDepositStore().updateCartDepositTotal(response.payable);
        storeDiscountCode(response);
      } else {
        console.log('--- NO RESPONSE ---');
        clearDiscountCode();
      }
      return response;
    });
  };

  const removeDiscountCode = async ({
    cartToken,
    code,
  }: {
    cartToken: string;
    code: string;
  }) => {
    return helperRemoveDiscountCode(cartToken, code).then((data) => {
      useDepositStore().updateCartDepositTotal(data.payable);
      clearDiscountCode();
      return data;
    });
  };

  return {
    currentDeepLinkCode,
    discountAmount,
    discountCode,
    discountObject,
    discountQuery,
    subtotal,
    total,
    clearDiscountCode,
    storeDeepLinkCode,
    storeDiscountCode,
    validateDiscountCode,
    removeDiscountCode,
  };
});