
import { defineComponent, PropType } from 'vue';
import { WindcaveConfig } from '@white-label-types/parking-checkout';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  props: {
    paymentsConfig: {
      type: Object as PropType<WindcaveConfig>,
      required: true,
    },
    totalValue: {
      type: Number,
      required: true,
    },
    isFormInvalid: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    showError: false,
    currency: getAppVariable('default_currency'),
    // The following environment variables will be moved to Admin Extranet
    googlePayBusinessId: process.env.NUXT_ENV_PAYMENT_GOOGLE_BUSINESS_ID,
    googlePayMerchantId: process.env.NUXT_ENV_PAYMENT_GOOGLE_MERCHANT_ID,
    applePayMerchantId: process.env.NUXT_ENV_PAYMENT_APPLE_MERCHANT_ID,
  }),

  computed: {
    isApplePayEnabled(): boolean {
      return this.$launchDarkly.variation('IMP-1275_Windcave_Enable_Apple_Pay') && Boolean(this.applePayMerchantId);
    },
    isGooglePayEnabled(): boolean {
      return this.$launchDarkly.variation('IMP-1275_Windcave_Enable_Google_Pay') && Boolean(this.googlePayMerchantId);
    },
    isMobileMenuEnabled(): boolean {
      return this.$launchDarkly.variation('IMP-1300_Windcave_Enable_Mobile_Menu');
    },
    isMobilePaymentEnabled(): boolean {
      return this.isGooglePayEnabled || this.isApplePayEnabled;
    },
    /**
     * Disables the payment widget if the form is invalid and mobile payment is enabled.
     * Exception: If the mobile menu feature is enabled, the payment widget remains active.
     */
    disablePaymentWidgetForm(): boolean {
      return this.isFormInvalid && this.isMobilePaymentEnabled && !this.isMobileMenuEnabled;
    },
    /**
     * Retrieves the brand color in RGBA format from the partner configuration.
     */
    brandColour(): string {
      return getAppVariable('colours.brand') || getAppVariable('brand_color', '');
    },
  },

  mounted(): void {
    this.$global_eventEmitter.$on('windcaveHandleReload', this.handleWindcaveError);
  },

  beforeDestroy (): void {
    this.$global_eventEmitter.$off('windcaveHandleReload', this.handleWindcaveError);
  },

  methods: {
    handleWindcaveTransaction() {
      this.$emit('payment-completed', {
        payment_method_id: this.paymentsConfig.sessionId,
        merchant_id: process.env.NUXT_ENV_PAYMENT_MERCHANT_ID,
      });
    },

    /**
     * Handles the Windcave payment error event by showing the error message
     * and reinitializing the Windcave payment form, allowing the user to retry the payment.
     */
    handleWindcaveError(shouldShowEror: false) {
      if (shouldShowEror) {
        this.showError = true;
      }

      this.$emit('windcaveWidgetStartedLoading');
      this.$refs.payment?.createWindcavePaymentForm();
    },

    /**
     * Triggers the submit button inside 'windcave-ui' custom element.
     * This button is hidden with data-basic-styling property.
     */
    submitForm() {
      const btn: HTMLButtonElement | null = document.querySelector('#windcave-ui__submit');
      btn?.click();
    },
  },
})
