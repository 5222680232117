
// Packages
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getDomainUrl, DOMAINS_NAMES } from '@white-label-helper/switch-app';

// Stores
import { useDiscountCodeStore } from '@white-label-store/discount-code';

// Constants
import {
  NAMED_ROUTES,
  PROGRESS_INDICATOR_PAGES,
  PRODUCT_TYPES,
} from '@white-label-configuration/constants';

// Components
import { useCartCheckoutStore } from '@white-label-store/cart-checkout';

// Components
import ProgressIndicator from '../progress-indicator/progress-indicator.vue';
import Navbar from '../navbar/navbar.vue';


export default defineComponent({
  name: 'CheckoutNavBar',

  components: {
    NavbarCheckout: Navbar,
    ProgressIndicator,
  },

  data() {
    return {
      NAMED_ROUTES,
    };
  },

  computed: {
    ...mapState(useCartCheckoutStore, {
      readCartItems: 'readCartItems',
      readCartItemsType: 'readCartItemsType',
    }),

    ...mapState(useDiscountCodeStore, {
      discountQuery: 'discountQuery',
    }),

    cartItemsType() {
      return this.readCartItemsType;
    },

    isLounges(): boolean {
      return this.cartItemsType === 'lounges';
    },

    cartItems() {
      return this.readCartItems;
    },

    isCancellationProtectionAvailable(): boolean {
      return this.cartItems?.[0]?.inventory_option?.details
        ?.cancellation_protection?.is_available;
    },

    productTypes() {
      return getAppVariable('product_types_available') ?? [];
    },

    bookingAppUrls(): { [key: string]: string } {
      const crossSellExtrasAvailable = this.cartItems[0]?.meta?.inventory_metadata?.['has_lounges']
        || this.cartItems[0]?.meta?.inventory_metadata?.['has_fasttrack'];
      const extrasProductsAvailable = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);
      const urls = {
        home: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.home}`,
        search: `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.search}`,
        select_extras: '',
      }

      if (crossSellExtrasAvailable) {
        urls.select_extras = this.$launchDarkly.variation('EM-369-CROSS-SELL-MIXED-RESULTS') === true
          ? `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.travel_extras}`
          : `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.select_extras.lounges}`;
      } else if (extrasProductsAvailable) {
        urls.select_extras = `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.select_extras.product}`;
      } else {
        urls.select_extras = `${getDomainUrl(DOMAINS_NAMES.ECOMMERCE)}${NAMED_ROUTES.select_extras.default}`;
      }

      return urls;
    },

    displayProgressIndicator(): boolean {
      // Need to case this as 'checkout' out as it's the only value it's looking for and won't accept a string
      const routeName = this.$route.name as 'checkout';
      return (
        this.cartItemsType !== '' &&
        Object.values(PROGRESS_INDICATOR_PAGES).includes(routeName)
      );
    },

    partner_name() {
      return getAppVariable('partner_name');
    },

    logoURL() {
      return getAppVariable('logo');
    },

    logoRedirectURL() {
      return (
        getAppVariable('logo_redirect_url')
        || getDomainUrl(DOMAINS_NAMES.ECOMMERCE)
      );
    },

    shouldSkipExtrasStep(): boolean {
      const crossSellExtrasAvailable = this.cartItems[0].meta?.inventory_metadata?.['has_lounges'];
      const extrasProductsAvailable = this.productTypes.includes(PRODUCT_TYPES.EXTRAS);

      return !(crossSellExtrasAvailable || extrasProductsAvailable)
    },
  },
});
