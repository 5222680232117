import { cloneDeep, get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAppVariable<T = any>(key: string, defaultValue?: T): T {

  if (process.env['NUXT_ENV_PARTNER_CONFIG_JSON'] === undefined) {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable must be defined')
  }

  if (process.env['NUXT_ENV_PARTNER_CONFIG_JSON'] === '') {
    throw new ReferenceError('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable must be a raw JSON string')
  }

  let parsedPartnerConfig

  try {
    parsedPartnerConfig = JSON.parse(process.env.NUXT_ENV_PARTNER_CONFIG_JSON)
  }

  catch (error) {
    throw new Error('The "NUXT_ENV_PARTNER_CONFIG_JSON" environment variable is set but not parsable as JSON')
  }

  const variable = cloneDeep(get(parsedPartnerConfig, key));

  if (variable === undefined) {
    // WL-4568 Temporary solution
    console.warn(`Missing '${key}' in partner config object`);
    return defaultValue;
  }

  return variable;
}
